<template lang="pug">
  div
    configCreateValidator(@validated="checkValidatorBeforeSend" v-slot="validator")
      v-dialog(
        max-width="400px"
        :value="showing"
        @input="updateShowing"
      )
        template(v-slot:activator="{on}")
          v-btn(small color='primary'
            @click="toggle"
            :disabled="disabled"
            outlined
          ).mr-2 {{ title }}

        modal-body(
          :editData="formData"
          :formErrors="formErrors"
          :showing="showing"
          @back="toggle"
          title="Add exam"
        )
          template(v-slot:actions="modalProps")
            v-spacer
            v-btn(outlined width="80" @click="toggle").btn-common Cancel
            v-btn(
              color="primary"
              width="80"
              :loading="processing"
              @click="validator.validationBeforeSend({data: modalProps.formData})"
              data-test="cm-create-submit"
            ).btn-common Add
</template>

<script>
import CMExamConfig, { CM_EXAM_CONFIG } from '../classes/CMExamConfig'
import FormErrors from '@/util/form-errors'
import errorsMixin from '@/mixins/errors.mixin'
import actionService from "@/app/admin/modules/candidates/core/candidates-actions-service.js"

export default {
  name: 'QuickReserve',

  mixins: [errorsMixin],

  props: {
    disabled: Boolean,
    activeItems: Array,
    title: {
      type: String,
      default: 'Quick reserve'
    },
    isExchange: {
      type: Boolean,
      default: false
    },
    isReserve: {
      type: Boolean,
      default: false
    },
    isCreateExam: {
      type: Boolean,
      default: false
    },
    city: Number
  },

  data: () => ({
    showing: false,
    formErrors: new FormErrors(),
    formData: new CMExamConfig({
      [CM_EXAM_CONFIG.EXAM_TIME_FROM]: '13:20',
      [CM_EXAM_CONFIG.EXAM_TIME_TO]: '15:40'
    }),
    processing: false,
    svc: new actionService()
  }),

  methods: {
    toggle() {
      this.showing = !this.showing
    },

    updateShowing(val) {
      this.showing = val
    },

    checkValidatorBeforeSend(payload) {
      this.formErrors = payload.validator
      if (!this.formErrors.isValid()) {
        this.$notify({ text: 'Invalid fields', type: 'error' })
        return
      }
      this.send(payload)
    },

    async send({ data }) {
      try {
        this.processing = true
        let ids = this.activeItems.map(item => item.ID)
        let res = await this.svc.quickReserve(this.city, ids, {
          ...data,
          isExchange: this.isExchange
        })
        this.$notify({ text: 'Success reserve', type: 'success' })
        this.toggle()
        this.$emit('create', res)
      } catch (error) {
        this.processError(error, { redirectName: this.$ROUTER_NAMES.LOGIN_CRM })
      } finally {
        this.processing = false
      }
    }
  },
  components: {
    modalBody: () => import('./cm_exam/CMExamModalBody.vue'),
    configCreateProvider: () => import('./cm_exam/CMExamAddProvider.vue'),
    configCreateValidator: () => import('./cm_exam/CMCreateValidationProvider.vue'),
  }
}
</script>
